const colors = {
	transparent: 'transparent', // 0
	black: '#000', // 1
	white: '#ffffff', // 2
	textColor: '#292929', // 3
	lightTextColor: '#757575', // 4
	inactiveField: '#F7F7F7', // 5
	inactiveColor: '#767676', // 6
	inactiveBG: '#e6e6e6', // 7
	borderColor: '#DBDBDB', // 8
	lightBorderColor: '#ededed', //9
	primary: '#834CFA', // 10
	primaryHover: '#c2c7fb', // 11
	yellow: '#E5AD12', //12
	// link: '#911EFF', //12
	link: '#D10068', //12
	labelColor: '#767676', // 4
	inactiveButton: '#b7dbdd', // 6
	inactiveIcon: '#EBEBEB', // 7

	// primaryHover: '#3444f1', // 9
	secondary: '#c2c7fb', // 10
	secondaryHover: '#FF282F', // 11

	yellowHover: '#F29E02', // 13

	primaryBoxShadow: '0px 8px 20px -6px rgba(235,77,75,0.6)'
};

export default colors;
