import React from 'react';
import { IoLogoGithub, IoLogoFacebook, IoLogoTwitter, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';
import { SocialProfileFrame } from './SocialProfileFrame';
import SocialProfile from './SocialProfile';

type SocialProsProps = {};

const SocialPros: React.FunctionComponent<SocialProsProps> = () => {
	const SocialLinks = [
		{
			icon: <IoLogoGithub />,
			url: 'https://www.github.com/xiumingxu/',
			tooltip: 'Github'
		},
		{
			icon: <IoLogoInstagram />,
			url: 'https://www.instagram.com/xixovo/',
			tooltip: 'Instagram'
		},
		// {
		// 	icon: <IoLogoFacebook />,
		// 	url: 'https://facebook.com/x',
		// 	tooltip: 'Facebook'
		// },
		{
			icon: <IoLogoLinkedin />,
			url: 'https://www.linkedin.com/in/xiumingxu/',
			tooltip: 'Linked In'
		}
	];

	return (
		<SocialProfileFrame>
			<SocialProfile items={SocialLinks} />
		</SocialProfileFrame>
	);
};

export default SocialPros;
