import React from 'react';

export default class SmoothScrollComponent extends React.Component {
	componentDidMount() {
		import('smooth-scroll/dist/smooth-scroll.js').then((data) => {
			const SmoothScroll = data.default;

			SmoothScroll('a[href*="#"]', {
				speed: 300,
				speedAsDuration: true
			});
		});
	}
	render() {
		return null;
	}
}
